@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}
